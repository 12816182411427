import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Loading.css';

const Loading = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/account')) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 1000); // 1 second

      return () => clearTimeout(timer);
    }
  }, [location]);

  if (!isVisible) return null;

  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
