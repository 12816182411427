import { ADD_APPLICATION, REMOVE_APPLICATION } from './studentAppActions';

const initialState = {
  applications: [],
};

const studentAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APPLICATION:
      return {
        ...state,
        applications: [...state.applications, action.payload],
      };
    case REMOVE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(app => app.id !== action.payload),
      };
    default:
      return state;
  }
};

export default studentAppReducer;
