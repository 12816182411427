import { createStore, combineReducers } from 'redux';
import authReducer from './redux/authReducer';
import studentAppReducer from './redux/studentAppReducer';

// Define initial state
const initialState = {
  isAuthenticated: false,
  user: null,
};

// Define a reducer
const rootReducer = combineReducers({
  auth: authReducer,
  studentApplications: studentAppReducer,
  // Add other reducers here
});

// Create a Redux store
const store = createStore(rootReducer, initialState);

export default store;
