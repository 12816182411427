// Action Types
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const REMOVE_APPLICATION = 'REMOVE_APPLICATION';

// Action Creators
export const addApplication = (application) => ({
  type: ADD_APPLICATION,
  payload: application,
});

export const removeApplication = (id) => ({
  type: REMOVE_APPLICATION,
  payload: id,
});
